import { useMemo } from 'react';
import { FeatureFlag } from 'controllers/_exports';
import Organization from 'infrastructure/Organization';

export const useShouldShowAutoRating = () => {
  const isEnabled = FeatureFlag.enabled('AUTORATING');
  const ai_integration = Organization.getSelectedOrg()?.ai_integration;

  const enabled = useMemo(() => {
    if (isEnabled && ai_integration) return true;
    return false;
  }, [isEnabled, ai_integration]);

  return enabled;
};

export const useShouldNotRetaken = () => {
  const isEnabled = FeatureFlag.enabled('NOT_RETAKEN');
  const ai_integration = Organization.getSelectedOrg()?.ai_integration;

  const enabled = useMemo(() => {
    if (isEnabled && ai_integration) return true;
    return false;
  }, [isEnabled, ai_integration]);

  return enabled;
};
