import { Avatar } from '@material-ui/core';
import User from 'infrastructure/User';
import { components } from 'react-select';

const RoleplayUserOption = (props) => {
  const { Option } = components;
  if (props?.data?.value === -1) return <Option {...props}>{props?.data?.label}</Option>;
  return (
    <Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
        <Avatar
          src={props?.data?.user?.profile_pic}
          style={{ width: 20, height: 20, fontSize: '0.75rem', background: '#87a0b4' }}
        >
          {User.getInitial(props.data?.user)}
        </Avatar>
        {User.getUserName(props.data?.user)}
      </div>
    </Option>
  );
};

export default RoleplayUserOption;
