import React, { useMemo, useState } from 'react';
import { STATUS_ID } from 'features/roleplay/RoleplayConstants';
import { CustomButton } from 'shared_ui_components';
import styles from './RoleplayDashboardList.module.scss';
import Organization from 'infrastructure/Organization';
import { useLocation, useHistory } from 'react-router-dom';
import { useCreateReminder } from 'features/roleplay/Roleplay.queries';

const RoleplayCardButton = ({ roleplay }) => {
  let location = useLocation();
  let history = useHistory();
  const { mutate: createReminder } = useCreateReminder();

  const remindButton = useMemo(() => {
    if (Organization.isRep()) return false;
    return [STATUS_ID.ONE_DAY_LEFT, STATUS_ID.LAST_DAY, STATUS_ID.OUT_OF_TIME].includes(
      roleplay?.status?.id
    );
  }, [roleplay?.status?.id]);

  const repRetake = useMemo(
    () => !Organization.isRep() && roleplay?.status?.id === STATUS_ID.RETAKEN,
    [roleplay?.status?.id]
  );

  const buttonLabel = useMemo(() => {
    if (remindButton) return 'Remind';
    if (roleplay?.status?.id === STATUS_ID.INITIATED) return 'Start';
    if (roleplay?.status?.id === STATUS_ID.SUBMITTED || repRetake) return 'Review';
    return 'Continue';
  }, [remindButton, roleplay?.status?.id, repRetake]);

  const disabled = useMemo(() => {
    if (Organization?.isRep()) return false;
    return remindButton && roleplay?.be_reminder_sent;
  }, [roleplay?.be_reminder_sent, remindButton]);

  const className = useMemo(() => {
    if (!remindButton) return 'secondaryBtn';
    if (roleplay?.be_reminder_sent) return styles.remindBtnDisabled;
    return styles.remindBtn;
  }, [remindButton, roleplay?.be_reminder_sent]);

  const [clicked, setClicked] = useState(false);
  const handleClick = () => {
    if (remindButton) {
      if (!clicked) createReminder({ roleplay_user_uuid: roleplay.uuid });
      setClicked(true);
      return;
    }
    if (buttonLabel !== 'Review') {
      history.push({
        pathname: `/roleplay/${roleplay?.uuid}`,
        state: {
          prevPath: `${location.pathname}${location.search ? location.search : ''}`,
        },
      });
    } else {
      history.push({
        pathname: `/roleplay-review/${roleplay?.uuid}`,
        state: {
          prevPath: `${location.pathname}${location.search ? location.search : ''}`,
        },
      });
    }
  };

  return (
    <CustomButton
      onClick={handleClick}
      className={className}
      style={{ width: '100%' }}
      disabled={disabled}
    >
      {buttonLabel}
    </CustomButton>
  );
};

export default RoleplayCardButton;
