import React, { useState, useEffect, useMemo } from 'react';
import SubmitRoleplayCard from './SubmitRoleplayCard';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import { CustomButton } from 'shared_ui_components';
import styles from './SubmitRoleplay.module.scss';
import { useSubmitRoleplay } from 'features/roleplay/Roleplay.queries';
import { useLocation, useHistory } from 'react-router-dom';

const SubmitRoleplayContent = () => {
  const roleplayView = useRoleplayStore((state) => state?.roleplayView);
  const [scenarios, setScenarios] = useState([]);
  const { mutate: submitRoleplay, isLoading } = useSubmitRoleplay();
  let location = useLocation();
  let history = useHistory();
  useEffect(() => {
    setScenarios(roleplayView?.scenarios || []);
  }, [roleplayView]);

  const handleSubmit = () => {
    submitRoleplay(
      { roleplay_id: roleplayView.uuid },
      {
        onSuccess: (response) => {
          history.push({
            pathname: `/roleplay`,
            state: {
              prevPath: `${location.pathname}${location.search ? location.search : ''}`,
            },
          });
        },
      }
    );
  };

  const enabled = useMemo(
    () => scenarios.every((item) => item.response && item.response.video),
    [scenarios]
  );

  return (
    <div>
      {scenarios.map((scenario, index) => {
        return (
          <SubmitRoleplayCard
            title={scenario.name !== 'Default' ? scenario.name : roleplayView?.name}
            description={
              scenario.description ? scenario.description : roleplayView?.role_play.description
            }
            index={index + 1}
            completed={scenario.response.video ? true : false}
            key={index}
          />
        );
      })}
      <div className={styles.submitButton}>
        <CustomButton onClick={handleSubmit} disabled={!enabled || isLoading}>
          Submit
        </CustomButton>
      </div>
    </div>
  );
};

export default SubmitRoleplayContent;
