import React, { useMemo } from 'react';
import styles from '../AIFeatures.module.scss';
import { STATUS } from '../constants';
import classNames from 'classnames';
import { Skeleton } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';
const AIFeedback = ({ ai_feedback = '', ai_feedback_status }) => {
  const title = useMemo(() => {
    if (ai_feedback_status === STATUS.NOT_SUPPORTED) return 'AI-Powered Feedback is not available.';
    return '';
  }, [ai_feedback_status]);

  const text = useMemo(() => {
    if (ai_feedback_status === STATUS.IN_PROGRESS)
      return "We're analyzing your content to ensure you receive the best feedback.";
    if (ai_feedback_status === STATUS.NOT_SUPPORTED)
      return "This could happen if the video is muted or doesn't have any sound.";
    return ai_feedback;
  }, [ai_feedback_status, ai_feedback]);

  const skeletons = useMemo(() => {
    if (ai_feedback_status !== STATUS.IN_PROGRESS) return [];
    const widths = [30, 35, 40, 50, 60, 70];
    const aux = [];
    for (let index = 0; index < 19; index++)
      aux.push(widths[Math.floor(Math.random() * widths.length)]);
    return aux;
  }, [ai_feedback_status]);

  if (ai_feedback_status === STATUS.NOT_FOUND)
    return (
      <div className={styles.error}>
        <p className={styles.textError}>AI-Powered Feedback is not available.</p>
      </div>
    );
  return (
    <div
      className={classNames([
        ai_feedback_status === STATUS.SUCCESS && styles.success1,
        ai_feedback_status === STATUS.IN_PROGRESS && styles.loading1,
        ai_feedback_status === STATUS.NOT_SUPPORTED && styles.error,
      ])}
    >
      {title !== '' && <p className={styles.titleError}>{title}</p>}

      {ai_feedback_status === STATUS.IN_PROGRESS ? (
        <div className={styles.loadingFeedback}>
          <CircularProgress size={15} disableShrink variant="indeterminate" />
          <p className={styles.loadingText}>{text}</p>
        </div>
      ) : (
        <p
          className={
            ai_feedback_status === STATUS.NOT_SUPPORTED ? styles.textError : styles.textFeedback
          }
        >
          {text === '' && ai_feedback_status === STATUS.SUCCESS
            ? 'AI-Powered Feedback is not available.'
            : text}
        </p>
      )}
      {ai_feedback_status === STATUS.IN_PROGRESS && (
        <div className={styles.loadingContainer}>
          {skeletons.map((width, i) => (
            <Skeleton key={i} variant="text" width={`${width}px`} height={12} />
          ))}
        </div>
      )}
    </div>
  );
};

export default AIFeedback;
