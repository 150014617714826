import React, { useMemo, useState } from 'react';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import { InputSelect } from 'shared_ui_components';
import { useGetListUsersRepQuery } from 'features/roleplay/Roleplay.queries';
import { FeatureFlag } from 'controllers/_exports';
import styles from './AssignRoleplay.module.scss';
import RoleplayUserOption from './RoleplayUserOption';
import User from 'infrastructure/User';

const RoleplaySelectAssign = () => {
  // Get users information
  const { data: usersListRep = [], isLoading } = useGetListUsersRepQuery();

  const formDataAssignRoleplay = useRoleplayStore((state) => state?.formDataAssignRoleplay);
  const setFormDataAssignRoleplay = useRoleplayStore((state) => state?.setFormDataAssignRoleplay);
  const userWithRoleplay = useRoleplayStore((state) => state?.userWithRoleplay);
  const setUserWithRoleplay = useRoleplayStore((state) => state?.setUserWithRoleplay);
  const scenariosList = useRoleplayStore((state) => state?.scenariosList);

  const [valueList, setValueList] = useState([]);

  const options = useMemo(() => {
    if (!usersListRep?.length > 0) return [];
    return usersListRep.map((user) => {
      return {
        label: User.getUserName(user?.user),
        value: user.user.uuid,
        roleplay: user.role_plays,
        user: user?.user,
      };
    });
  }, [usersListRep]);

  useMemo(() => {
    setUserWithRoleplay(
      formDataAssignRoleplay?.users?.filter((user) => {
        return user?.roleplay?.some((role) => {
          const matchesRoleplay = role.uuid === formDataAssignRoleplay?.roleplay?.value;
          const selectedScenarios = scenariosList.filter((scenario) => scenario.isSelected);
          const matchDefaultScenario = role.scenarios[0].name === 'Default';
          const matchesScenario =
            role.scenarios.length === selectedScenarios.length &&
            role.scenarios?.every((scenario) =>
              selectedScenarios?.some((selectedScenario) => selectedScenario.uuid === scenario.uuid)
            );
          return matchesRoleplay && (matchesScenario || matchDefaultScenario);
        });
      })
    );
  }, [formDataAssignRoleplay, scenariosList, setUserWithRoleplay]);

  const handleChange = (values) => {
    let newUsers = values;
    if (values.some((item) => item.value === -1)) {
      newUsers = usersListRep.map((user) => {
        return {
          label: User.getUserName(user?.user),
          value: user.user.uuid,
          roleplay: user.role_plays,
          user: user?.user,
        };
      });
    }
    setValueList(newUsers);
    setFormDataAssignRoleplay({ ...formDataAssignRoleplay, users: newUsers });
  };

  return (
    <div className={styles.selectAssignWrapper}>
      <InputSelect
        disabled={isLoading}
        isMulti
        closeMenuOnSelect={true}
        options={[
          {
            value: -1,
            label:
              options?.length === 0 || options?.length === valueList?.length
                ? 'There are no more sales reps to select'
                : 'All sales reps',
          },
          ...options,
        ]}
        label="Sales Reps"
        placeholder={isLoading ? 'Loading...' : 'Select Sales Reps'}
        onChange={(values) => handleChange(values)}
        noOptionsMessage={() => 'No sales reps found'}
        isClearable
        value={valueList}
        errorValues={
          FeatureFlag.enabled('ROLEPLAY_NEW_LOGIC_ASSIGN')
            ? userWithRoleplay?.map((u) => u?.value)
            : []
        }
        components={{ Option: RoleplayUserOption }}
      />
      {FeatureFlag.enabled('ROLEPLAY_NEW_LOGIC_ASSIGN') && userWithRoleplay?.length > 0 && (
        <div className={styles.usersWithRoleplayContainer}>
          <p className={styles.userWRText}>
            This roleplay will be assigned to {valueList.length - userWithRoleplay?.length} of{' '}
            {valueList.length} selected Sales Reps.
          </p>
        </div>
      )}
    </div>
  );
};

export default RoleplaySelectAssign;
